import React from "react"
import Img from "gatsby-image"
import { graphql, useStaticQuery } from "gatsby"

import "../styles/object/component/card.scss"
import "../styles/object/component/tag.scss"
import "../styles/object/component/typography.scss"

const query = graphql`
  query {
    images: allFile {
      edges {
        node {
          relativePath
          childImageSharp {
            fluid(maxWidth: 300, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default (props) => {
  const data = useStaticQuery(query)

  const image = data.images.edges.find((n) => {
    return n.node.relativePath.includes(props.imageName)
  })

  return (
    <div className="cardBusiness">
      <div
        className="cardBusiness_image"
        data-sal="slide-right"
        data-sal-duration="800"
        data-sal-delay="200"
        data-sal-easing="easeOutCubic"
      >
        <Img
          fluid={image ? image.node.childImageSharp.fluid : null}
          alt={props.imageAlt}
        />
      </div>
      <div
        className="c-card"
        data-sal="slide-left"
        data-sal-duration="800"
        data-sal-delay="200"
        data-sal-easing="easeOutCubic"
      >
        <div className="cardBusiness_title">{props.title}</div>
        <div className="cardBusiness_company">{props.company}</div>
        <div className="cardBusiness_tag">
          {props.tags
            ? props.tags.map((name, index) => (
                <label key={index} className="c-tag">
                  {name}
                </label>
              ))
            : null}
        </div>
        <div className="cardBusiness_description">{props.content}</div>
      </div>
    </div>
  )
}
